import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import BasePage from './BasePage';

const ContentfulPolicyPage = ({ data, pageContext }) => {
  const policy = useMemo(
    () => data?.contentfulPolicy?.policyPages?.find((page) => page.region === pageContext.region),
    []
  );

  const policyPageData = useMemo(
    () => data?.contentfulPolicy && policy && { ...data.contentfulPolicy, ...policy },
    []
  );

  return <BasePage pageContext={{ ...pageContext, ...policyPageData }} />;
};

export const query = graphql`
  query ($slug: String!, $language: String!, $region: String!) {
    contentfulPolicy(
      slug: { eq: $slug }
      policyPages: { elemMatch: { node_locale: { eq: $language }, region: { eq: $region } } }
    ) {
      name
      policyPages {
        title
        region
        showTitle
        disclaimer {
          raw
        }
        body {
          raw
          references {
            title
            description
            contentful_id
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ContentfulPolicyPage;
