import React from 'react';
import { Link as UnstyledLink } from 'gatsby';
import styled from 'styled-components';

import { themeCssVar as globalTheme } from '@tinderapp/ui-react';

const RelatedArticle = ({ path, children }) => <Link to={path}>{children}</Link>;

const Link = styled(UnstyledLink)`
  color: ${globalTheme.color.brandPrimary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default RelatedArticle;
