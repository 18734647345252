import React from 'react';
import styled from 'styled-components';

import BreadcrumbItem from './BreadcrumbItem';

type BreadcrumbsProps = {
  className?: string;
  items: BreadcrumbItem[];
};

const Breadcrumbs = ({ className, items }: BreadcrumbsProps) =>
  items?.length > 1 ? (
    <BreadcrumbContainer className={className}>
      {items.map(({ title, path }, index) => (
        <BreadcrumbItem
          key={title}
          title={title}
          path={path}
          renderLink={path && index !== items.length - 1}
        />
      ))}
    </BreadcrumbContainer>
  ) : null;

const BreadcrumbContainer = styled.ol`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default Breadcrumbs;
