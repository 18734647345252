import React, { useMemo } from 'react';
import styled from 'styled-components';

import { LocalizationProvider } from '../contexts/LocalizationContext';
import { DEFAULT_LOCALE, getLocale, getMessages } from '../data/messages';
import UnstyledBreadcrumbs from '../components/Breadcrumbs';
import UnstyledRelatedArticles from '../components/RelatedArticles';
import { LOCALIZE_LINKS_POLICY_PAGES } from '../config';
import { minWidthMedium } from '../lib/styles';
import { HEADER_HEIGHT_SMALL, HEADER_HEIGHT_LARGE } from '../lib/sizing';
import SEO from '../components/Seo';
import LocalizedLinkContainer from '../components/LocalizedLinkContainer';
import TextContent from '../components/TextContent';
import Layout from '../components/PolicyLayout';
import formatRichtext from '../lib/formatRichtext';
import RendererdMarkdownContent from '../components/RendererdMarkdownContent';

const BasePage = ({
  locale = DEFAULT_LOCALE,
  supportedLanguages = {},
  redirectMap = {},
  pageContext,
}) => {
  const mainContent = useMemo(() => {
    if (pageContext.markdown) {
      return (
        <RendererdMarkdownContent
          rawHtmlContent={pageContext.markdown}
          locale={getLocale(locale)}
        />
      );
    }

    if (pageContext.body) {
      return formatRichtext(pageContext.body);
    }
  }, [pageContext.body, pageContext.html, pageContext.markdown, locale]);

  const disclaimer = useMemo(
    () => formatRichtext(pageContext.disclaimer),
    [pageContext.disclaimer]
  );
  
  return (
    <LocalizationProvider
      locale={getLocale(locale)}
      messages={getMessages(locale)}
      localizedPaths={supportedLanguages}
      redirectMap={redirectMap}
    >
      <Layout locale={getLocale(locale)}>
        <Breadcrumbs items={pageContext.breadcrumbs} />
        <SEO title={pageContext.title} lang={locale} description={pageContext.description ?? ''} />
        <ArticleBody tagName="article">
          <TextContent>
            {disclaimer}
            {pageContext.hideTitle ? null : <Title>{pageContext.title}</Title>}
            {mainContent}
          </TextContent>
        </ArticleBody>
        <RelatedArticles items={pageContext.relatedArticles} />
      </Layout>
    </LocalizationProvider>
  );
};

const ArticleBody = styled(LOCALIZE_LINKS_POLICY_PAGES ? LocalizedLinkContainer : 'span')`
  a[name] {
    position: relative;
    top: -${HEADER_HEIGHT_SMALL}px;

    @media ${minWidthMedium} {
      top: -${HEADER_HEIGHT_LARGE}px;
    }
  }
`;

const Breadcrumbs = styled(UnstyledBreadcrumbs)`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  margin: 0;
`;

const RelatedArticles = styled(UnstyledRelatedArticles)`
  margin-top: 40px;
`;

export default BasePage;
