import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { themeCssVar as globalTheme } from '@tinderapp/ui-react';

import CaretUp from '../../ui-icons/caret-up.svg';

type BreadcrumbItem = {
  title: string;
  path?: string;
  renderLink?: boolean;
};

const BreadcrumbItem = ({ title, path, renderLink }: BreadcrumbItem) => (
  <StyledBreadcrumbItem key={title}>
    {renderLink ? (
      <>
        <Link to={path}>{title}</Link>
        <Caret />
      </>
    ) : (
      title
    )}
  </StyledBreadcrumbItem>
);

const StyledBreadcrumbItem = styled.li`
  position: relative;
  color ${globalTheme.color.textPrimary};
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }

  &:first-of-type {
    padding-left: 0;
  }
`;

const Caret = styled(CaretUp)`
  margin: 0 4px;
  transform: rotate(90deg);
  width: 12px;
  fill: ${globalTheme.color.textPrimary};
`;

export default BreadcrumbItem;
