import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import RelatedArticle from './RelatedArticle';
import { FONT_WEIGHT_BOLD } from '@tinderapp/ui-core';

type RelatedArticlesProps = {
  className?: string;
  items: {
    title: string;
    path: string;
  }[];
};

const RelatedArticles = ({ className, items }: RelatedArticlesProps) =>
  items?.length ? (
    <RelatedArticlesContainer className={className}>
      <RelatedArticlesTitle>
        <FormattedMessage id="page.relatedArticles" />
      </RelatedArticlesTitle>
      <RelatedArticleList>
        {items.map((link) => (
          <ListItem key={link.path}>
            <RelatedArticle path={link.path}>{link.title}</RelatedArticle>
          </ListItem>
        ))}
      </RelatedArticleList>
    </RelatedArticlesContainer>
  ) : null;

const RelatedArticlesContainer = styled.div`
  margin-top: 2rem;
`;

const RelatedArticlesTitle = styled.h2`
  margin-bottom: 1rem;
  font-weight: ${FONT_WEIGHT_BOLD};
`;

const RelatedArticleList = styled.ul`
  list-style: none;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.75rem;
`;

export default RelatedArticles;
